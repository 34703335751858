import { createSlice } from "@reduxjs/toolkit";
import { heroData } from "../Constants/Data";

export const heroSlice = createSlice({
    name: "image",
    initialState: {
        data: { heroData }
    },
    reducers: {
        image: (state, action) => {
            state.data = action.payload

        }
    },

}
)
export const { image } = heroSlice.actions;
export default heroSlice.reducer;