import React from 'react'
import './footer.css';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import { Link, NavLink } from 'react-router-dom';
import { socialIcon } from '../../Constants/Data';



const Footer = () => {


    return (
        <div className='bottom'>
            <Container >

                <div className='bottom'>

                    <div className='title-wrapper'>


                        <h4 className='title' sm={12}>Location</h4>
                        <ul className='list-unstyled f-left' >
                            <li >5/3, Puthu Thambhi 2nd Street,</li>
                            <li>Anagaputhur,</li>
                            <li> Chennai - 600 070 </li>
                        </ul>


                    </div>
                    <div className='title-wrapper'>

                        <h4 className='title' sm={12} > Works</h4>
                        <ul className='list-unstyled f-left'>
                            <li><NavLink className="list-item" to="/services"> Services</NavLink></li>
                            <li><NavLink className="list-item" to="/web-development">Web Devlopment</NavLink></li>
                            <li><NavLink className="list-item" to="/technology-consultation">Technology Consultant</NavLink></li>
                            <li><NavLink className="list-item" to="/digital-marketing">Digital Marketing</NavLink></li>
                        </ul>


                        {/* { className: 'svg-003  col-lg-4 col-md-4 col-sm-6 col-xs-12 padding_zer' } */}

                    </div>
                    <div className='title-wrapper'>

                        <h4 className='title' sm={12}>Socials</h4>
                        <div className='bottom'>
                            {socialIcon.map((icon, id) => (
                                <Link to={icon.path} key={icon.id}
                                    className="svg-003 col-lg-4 col-md-4 col-sm-6 col-xs-12 padding_zero"
                                    target="_blank"

                                    rel="noopener noreferrer" >
                                    {icon.icon}

                                </Link>
                            ))}
                        </div>


                    </div>


                </div>
                <hr />
                <div className='copyright'>
                    <Col>
                        <p>WebAppStudioz © Copyright 2024 |All Rights Reserved. </p>
                    </Col>
                </div>
            </Container >
        </div >
    )
}

export default Footer