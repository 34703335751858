
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Main from './Components/Main';
import { HelmetProvider } from 'react-helmet-async';




function App() {
  return (
    <div className="App">

      <HelmetProvider>
        <Main />
      </HelmetProvider>
    </div>
  );
}

export default App;
