import React from 'react'

import { NavLink } from "react-router-dom";
import { pages } from '../Constants/Data';
import Image from 'react-bootstrap/esm/Image'

const img = require('../assets/website-app-logo.png');


const NavHeader = () => {
  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light bg-light" id="header">
        <div className="container">
          <NavLink className="navbar-brand" to='/'>
            <span><Image className="logo-set" src={img} />
              <span className="logo-text">WebAppStudioz</span>
            </span>
          </NavLink>

          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              {pages.map((page, idx) => {
                if (page.subpages) {
                  return (
                    <li className="nav-item dropdown" key={idx}>
                      <NavLink className="nav-link dropdown-toggle" id={`navbarDropdown${idx}`} role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        {page.name}
                      </NavLink>
                      <ul className="dropdown-menu" aria-labelledby={`navbarDropdown${idx}`}>
                        {page.subpages.map((subpage, subIdx) => (
                          <li key={subIdx}>
                            <NavLink className="dropdown-item" to={subpage.path}>{subpage.name}</NavLink>
                          </li>
                        ))}
                      </ul>
                    </li>
                  );
                } else {
                  return (
                    <li className="nav-item" key={idx}>
                      <NavLink className="nav-link" to={page.path} exact="true">{page.name}</NavLink>

                    </li>
                  );
                }
              })}
            </ul>
          </div>
        </div>
      </nav>


    </div>
  )
}

export default NavHeader