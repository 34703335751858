import React from "react";
import NavHeader from "./NavHeader";
import { Routes, Route } from "react-router-dom"
import Footer from "./Footer";
import { lazy } from 'react';
import Home from "./Home";
import Nopage from "./NoPage";

const LazyAbout = lazy(() => import('./About'))
const LazyServices = lazy(() => import('./Services'))
const LazyCareer = lazy(() => import('./Career'))
const LazyContact = lazy(() => import('./Contact'))
const LazyWebDevlopment = lazy(() => import('./Devlopment'))
const LazyTechnology = lazy(() => import('./TechnologyConsult'))
const LazyDigitalMarketing = lazy(() => import('./DigitalMarketing'))





const Main = () => {

  return (
    <>


      <NavHeader />


      <Routes>
        <Route path='/'
          element={<Home />} />

        <Route path='/about' element={
          <React.Suspense fallback='Loading...'>
            <LazyAbout />
          </React.Suspense>
        } />
        <Route path='/services' element={
          <React.Suspense fallback='Loading...'>
            <LazyServices />
          </React.Suspense>
        } />
        <Route path='/careers' element={
          <React.Suspense fallback='Loading...'>
            <LazyCareer />
          </React.Suspense>
        } />


        <Route path='/web-development' element={
          <React.Suspense fallback='Loading...'>
            <LazyWebDevlopment />
          </React.Suspense>
        } />

        <Route path='/technology-consultation' element={
          <React.Suspense fallback='Loading...'>
            <LazyTechnology />
          </React.Suspense>
        } />
        <Route path='/digital-marketing' element={
          <React.Suspense fallback='Loading...'>
            <LazyDigitalMarketing />
          </React.Suspense>
        } />
        <Route path='/contact' element={
          <React.Suspense fallback='Loading...'>
            <LazyContact />
          </React.Suspense>
        } />


        <Route path='*' element={<Nopage />} />

      </Routes>

      <Footer />

    </>
  )
}

export default Main