import React from 'react'
import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import Image from 'react-bootstrap/esm/Image'
import './nopage.css'
import { useNavigate } from 'react-router-dom'
const img = require('../../assets/no-page.png');

const Nopage = () => {
    const navigate = useNavigate()
    return (
        <div>
            <section id="services" className='block services-block'>
                <Container >
                    <Row>
                        <Col sm={6} className='image-holder top-box'>
                            <Image className="img" src={img} />
                        </Col>

                        <Col sm={6} className='top-box'>
                            <button className='btn btn-warning top' onClick={() => navigate('/')}>Visit Home Page</button>
                        </Col>



                    </Row>
                </Container>
            </section>

        </div>
    )
}

export default Nopage;
