import React from 'react'
import { motion } from "framer-motion"
import { useNavigate } from 'react-router-dom'
const Button = () => {
    const navigate = useNavigate();
    return (
        <div>
            <motion.div
                variants={{
                    hidden: { opacity: 0, y: 75 },
                    visible: { opacity: 1, y: 0 }
                }}
                initial="hidden"
                animate="visible"
                transition={{ duration: 0.5, delay: 0.25 }}

                className="common-block">
                <div className='title-box'>
                    <h2 sm={12}>Unlock Growth Opportunities with Transformative Change</h2>
                    <motion.button
                        whileHover={{ scale: 1.1 }}
                        className='button-lst' onClick={() => navigate('/contact')}>Connect With Us</motion.button>
                </div>
            </motion.div></div>
    )
}

export default Button