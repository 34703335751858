import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import { motion } from "framer-motion"
import Hero from '../Hero';
import './home.css'
import Button from '../../Constants/Button';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Marquee from 'react-marquee-slider';
import { Helmet } from "react-helmet-async";
const Home = () => {
    const { data } = useSelector((state) => state.icons)
    const itDoc = useSelector((state) => state.displayIt)
    const clients = useSelector((state) => state.clientImg)

    const [setImagesLoaded] = useState(false);




    useEffect(() => {
        const preloadImages = () => {
            if (!itDoc.data || !Array.isArray(itDoc.data) || itDoc.data.length === 0) return;

            let loadedImages = 0;
            const totalImages = itDoc.data.length;

            itDoc.data.forEach(url => {
                const img = new Image();
                img.src = url;
                img.onload = () => {
                    loadedImages += 1;
                    if (loadedImages === totalImages) {
                        setImagesLoaded(true);
                    }
                };
            });
        };

        preloadImages();
    }, [itDoc.data, setImagesLoaded]);



    return (

        <div>
            <Hero />
            <Helmet>
                <title>WebAppStudioz | The Best Web Development Company in Chennai</title>
                <meta name="description" content="Ranked as The Best web designing company for small scale and Startups. Elevate your online presence and drive business growth with us. " />
            </Helmet>

            <section className="common-block ctr" >
                <div className='title-holder'>
                    <h1>Best web devlopment company for small scale and start ups</h1>
                    <h4 className='subtitle'>Our services are web development & digital marketing </h4>
                </div>
            </section>

            <div className='common-block'>
                <section >
                    <div className='title-holder'>
                        <h1>Technologies we use</h1>

                        <section className='box-holder'>
                            <Container className='flex-001'>
                                <motion.span
                                    initial={{ opacity: 0, scale: 0.5 }}
                                    animate={{ opacity: 1, scale: 1, y: 25 }}
                                    transition={{ duration: 0.5 }}
                                    className='right-box '>
                                    {
                                        data.map((svg, index) => {
                                            return (
                                                <Col key={index}>
                                                    {React.cloneElement(svg.icon, { className: 'svg-001  col-lg-4 col-md-4 col-sm-6 col-xs-12 padding_zer' })}

                                                </Col>
                                            )
                                        })
                                    }
                                </motion.span>
                            </Container>
                        </section>
                    </div>

                </section>
            </div>




            <section className="common-block">
                <div className='title-holder'>
                    <h1>Services we provide</h1>
                    <h4 className='subtitle'>Driving Growth Through Customized Web Development Solutions</h4>
                </div>
                <Container >
                    <div className='spl-ser'>
                        {itDoc.data.map((array, idx) => (
                            <motion.div
                                variants={{
                                    hidden: { opacity: 0, y: 75 },
                                    visible: { opacity: 1, y: 0 }
                                }}
                                initial="hidden"
                                animate="visible"
                                transition={{ duration: 0.5, delay: 0.25 }}
                                key={idx}>

                                {React.cloneElement(array.icon, { className: 'svg-002' })}
                                <p className='title-block'>{array.title}</p>
                                <p className='title-p'>
                                    {array.description}
                                </p>
                                <Link to={array.path} className="service-link btn-outline">
                                    Visit Now {array.rightArrow}
                                </Link>
                                {/* <p className='bold-font btn-outline' onClick={() => navigate('/services')}>Read more {array.rightArrow}</p> */}
                            </motion.div>

                        ))

                        }
                        <br />
                    </div>
                </Container>
            </section>



            <div className="common-block">
                <div className='title-holder'>
                    <h1 sm={12}>Our Clients</h1>
                    <h4 className='subtitle'>Here is a list of our clients which transformed business digitally</h4>
                </div>
                <Container  >

                    <div className='clients-box'>
                        <Marquee velocity={25} scatterRandomly={false}>
                            {
                                clients.data.map((img, idx) =>
                                (
                                    <div className="client-slide" key={idx}>
                                        <motion.img
                                            whileHover={{ scale: 1.1 }}

                                            className="img-holder" src={img.image} alt={img.alt} />
                                    </div>


                                )
                                )
                            }
                        </Marquee>

                    </div>

                </Container>
            </div >
            <Button />
        </div >
    )
}

export default Home