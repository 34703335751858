import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
// import { heroData } from '../Constants/Data';
import { useSelector } from 'react-redux';






const Hero = () => {
  const { data } = useSelector((state) => state.image)
  return (
    <>
      <section id="home" className="hero-block">
        <Carousel>
          {
            data.heroData.map((hero, id) => {
              return (
                <Carousel.Item key={id}>
                  <img className='d-block w-100 h-100' src={hero.image} alt={hero.alt} />

                </Carousel.Item>
              )
            })
          }
        </Carousel>
      </section>



    </>
  )
}

export default Hero