import { createSlice } from "@reduxjs/toolkit";
import { itData } from "../Constants/Data";



export const itSlice = createSlice({
    name: "it",
    initialState: {
        data: itData
    },
    reducers: {
        itInfo: (state, action) => {
            state.data = action.payload

        }
    },

}
)
export const { itInfo } = itSlice.actions;
export default itSlice.reducer;

