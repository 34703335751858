import { createSlice } from "@reduxjs/toolkit";
import { digital } from "../Constants/Data";



export const digitalSlice = createSlice({
    name: "digi",
    initialState: {
        data: digital
    },
    reducers: {
        digiData: (state, action) => {
            state.data = action.payload

        }
    },

}
)
export const { digiData } = digitalSlice.actions;
export default digitalSlice.reducer;

