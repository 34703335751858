import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import homeReducer from './Reducers/homeReducer';
import heroReducer from './Reducers/heroReducer';
import itReducer from './Reducers/itReducer';
import clientReducer from './Reducers/clientReducer';
import devReducer from './Reducers/devReducer';
import techReducer from './Reducers/techReducer';
import digitalReducer from './Reducers/digitalReducer';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import serviceReducer from './Reducers/serviceReducer';
import careerReducer from './Reducers/careerReducer';




const store = configureStore({
  reducer: {
    icons: homeReducer,
    image: heroReducer,
    displayIt: itReducer,
    clientImg: clientReducer,
    dev: devReducer,
    tech: techReducer,
    digi: digitalReducer,
    // enquiry: formReducer,
    serviceInfo: serviceReducer,
    career: careerReducer

  }
})


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

