import { createSlice } from "@reduxjs/toolkit";
import { svgData } from "../Constants/Data";



export const homeSlice = createSlice({
    name: "icons",
    initialState: {
        data: svgData
    },
    reducers: {
        svgIcon: (state, action) => {
            state.data = action.payload

        }
    },

}
)
export const { svgIcon } = homeSlice.actions;
export default homeSlice.reducer;

