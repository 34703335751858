import { createSlice } from "@reduxjs/toolkit";
import { devIcon } from "../Constants/Data";



export const devlopmentSlice = createSlice({
    name: "dev",
    initialState: {
        data: devIcon
    },
    reducers: {
        devIcons: (state, action) => {
            state.data = action.payload

        }
    },

}
)
export const { devIcons } = devlopmentSlice.actions;
export default devlopmentSlice.reducer;

