import { careerData } from "../Constants/Data";
import { createSlice } from "@reduxjs/toolkit";



export const careerSlice = createSlice({
    name: "career",
    initialState: {
        data: careerData
    },
    reducers: {
        devIcons: (state, action) => {
            state.data = action.payload

        }
    },

}
)
export const { careerIcons } = careerSlice.actions;
export default careerSlice.reducer;