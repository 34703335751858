import { createSlice } from "@reduxjs/toolkit";
import { techData } from "../Constants/Data";



export const techSlice = createSlice({
    name: "tech",
    initialState: {
        data: techData
    },
    reducers: {
        techImg: (state, action) => {
            state.data = action.payload

        }
    },

}
)
export const { techImg } = techSlice.actions;
export default techSlice.reducer;

