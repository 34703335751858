import { createSlice } from "@reduxjs/toolkit";
import { servicesData } from "../Constants/Data";



export const serviceSlice = createSlice({
    name: "service",
    initialState: {
        data: servicesData
    },
    reducers: {
        serviceInfo: (state, action) => {
            state.data = action.payload

        }
    },

}
)
export const { serviceInfo } = serviceSlice.actions;
export default serviceSlice.reducer;

