import { createSlice } from "@reduxjs/toolkit";
import { clientData } from "../Constants/Data";



export const clientSlice = createSlice({
    name: "clientImg",
    initialState: {
        data: clientData
    },
    reducers: {
        clientBanner: (state, action) => {
            state.data = action.payload

        }
    },

}
)
export const { clientBanner } = clientSlice.actions;
export default clientSlice.reducer;

